<template>
    <div>
        <div class="fatherLeftBox">
            <span v-for="(item,index) in MenuArray"
                  :class="{'activedMenu':index == SelectMenuData,'iconfont':true,[item.className]:true}"
                  :key="index"
                  @click="ClickLeftMenu(index)"
            >
                {{item.name}}
            </span>
        </div>
        <div class="LeftFunction" v-if="SelectMenuData == 1">
            <el-input size="mini" clearable v-model="SearchAddressData">
                <el-button slot="append" @click="SearchAddress" icon="el-icon-search">搜索</el-button>
            </el-input>
            <div>
                <div @click="ClickOneAddress(item)"
                     class="AddressDiv" v-for="(item,index) in SearchArray"
                     :key="index"
                >
                    {{item.address}}{{item.name}}
                </div>
            </div>
        </div>
        <div class="LeftFunction" style="width:auto" v-if="SelectMenuData == 0">
          <el-checkbox-group @change="CoverChangeFun" v-model="coverCheck">
            <el-checkbox :label="1">设备</el-checkbox>
            <el-checkbox :label="2">管线</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="LeftFunction" style="max-height:800px;overflow: auto" v-if="SelectMenuData == 2">
          <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="SearchEvent.input"
              size="mini"
              style="width:70%;margin-right:10px;"
          >
          </el-input>
          <el-button @click="SearchDataFun" size="mini">查询</el-button>
          <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="datePicker"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              style="width:100%;margin-top:10px;margin-bottom:10px;"
              size="mini"
              end-placeholder="结束日期">
          </el-date-picker>
          <div v-for="(item,index) in EventArray" @click="SelectEventItem(index,item)" :key="item.id" class="EventItemClass">
            <div style="font-size:14px;">
              事件类型：{{item.eventCodeName}}
            </div>
            <div style="font-size:14px;margin-top:5px;">
              {{item.eventAddress}}
            </div>
            <div class="EventItemBottom">
              <label style="color:#999;font-size:12px;">
                <i class="iconfont icon-renyuan"></i>
                {{item.createBy}}
              </label>
              <label style="color:#999;font-size:12px;">
                <i class="iconfont icon-rili"></i>
                {{item.createTime}}
              </label>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import {EventListCov} from "@/RequestPort/gis";

    export default {
        name: "GisLeftComponent",
        props:[''],
        data(){
            return{
              coverCheck:[1,2],
              SearchAddressData:'',
              autoOptions:{
                city:'唐山'
              },
              SelectMenuData:null,
              MenuArray:[
                {name:'图层',className:'icon-tuceng'},
                {name:'搜索',className:'icon-xingtaiduICON_sousuo--'},
                {name:'事件',className:'icon-rili'},
                // {name:'人员',className:'icon-renyuan'},
                {name:'测距',className:'icon-gongjuxiang'},
              ],
              SearchArray:[],
              SearchEvent:{
                endTime:'',
                input:'',
                startTime:'',
              },
              datePicker:'',
              EventArray:[],
              EventItemNum:null,
            }
        },
        methods:{
          SearchEventListFun(){
            EventListCov(this.SearchEvent).then((e)=>{
              this.EventArray = e.data
            })
          },
          SearchDataFun(){
            if(this.datePicker){
              this.SearchEvent.startTime = this.datePicker[0]
              this.SearchEvent.endTime = this.datePicker[1]
              this.SearchEventListFun()
            }else{
              this.SearchEvent.startTime = ''
              this.SearchEvent.endTime = ''
              this.SearchEventListFun()
            }
          },
          SelectEventItem(e,item){
            this.EventItemNum = e
            this.$emit('addMarker',item)
          },
          CoverChangeFun(e){
            this.$emit('CoverChange',e)
          },
          ClickOneAddress(e){
            this.$emit('ClickOneAddress',e)
          },
          SearchAddress(){
            AMap.plugin('AMap.PlaceSearch',()=>{
              var autoOptions = {
                city: '唐山'
              }
              var placeSearch = new AMap.PlaceSearch(this.autoOptions);
              placeSearch.search(this.SearchAddressData, (status, result)=>{
                this.SearchArray = result.poiList.pois
                this.$emit('SearchAddres',this.SearchArray)
              })
            })
          },
          ClickLeftMenu(e){
            if(this.SelectMenuData == e ){
              this.SelectMenuData = null
              this.$emit('ClickMenu',e)
            }else{
              this.SelectMenuData = e
              this.$emit('ClickMenu',e)
            }
          },
        },
        watch:{
            SearchAddressData(e){
                this.SearchArray = []
                this.$emit('SearchAddressChange')
            },
            SelectMenuData(val){

            }
        },
        mounted() {
            this.SearchEventListFun()
        }
    }
</script>
<style scoped>
    .EventItemClass{
      cursor:pointer;
      padding:5px 0;
    }
    .EventItemClass:hover{
      background-color:#eee
    }
    .EventItemBottom{
      display: flex;
      justify-content: space-between;
      align-items:center;
      overflow: hidden;
      margin-top:3px;
      border-bottom:1px solid #eee
    }
    .AddressDiv{
        padding:10px;
    }
    .AddressDiv:hover{
        background: #f5f2f0;
    }
    .LeftFunction{
        padding:20px;
        position: absolute;
        content: "";
        z-index: 999;
        left: 90px;
        top: 30px;
        background: #fff;
        width: 250px;
        border-radius: 4px;
        -webkit-box-shadow: 0 0 10px #ccc;
        box-shadow: 0 0 10px #ccc;
    }
    .fatherLeftBox{
        position: absolute;
        content: "";
        z-index: 999;
        left: 10px;
        top: 30px;
        height: auto;
        display: flex;
        flex-direction:column;
    }
    .fatherLeftBox span {
        background: #fff;
        color: #666;
        text-align: center;
        margin-bottom: 5px;
        padding: 10px 10px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 12px;
        -webkit-box-shadow: 0 0 10px #ccc;
        box-shadow: 0 0 10px #ccc;
    }
    .fatherLeftBox span:hover{
        color:white;
        background: #409eff;
    }
    .activedMenu{
        color:white!important;
        background: #409eff!important;
    }
    .LeftFunction::-webkit-scrollbar-thumb{
      border-radius: 0;
      border-style: dashed;
      background-color: #ccc;
      border-color: transparent;
      border-width: 1.5px;
      background-clip: padding-box;
    }
    .LeftFunction::-webkit-scrollbar{
      width:9px!important;
      height:9px!important;
    }
</style>