import GisLeftComponent from "./GisComponent/GisLeftComponent";
import {equipmentListCov,LineListCov,PersonListCov,} from '../../RequestPort/gis/index'
import searchAddress from '../../assets/searchAddress.png'
export default {
    name: "index",
    components:{
        GisLeftComponent
    },
    data(){
        return {
            Map:{},
            mouseTool:{},
            MenuNum:'',
            ruler:{},
            AllDevice:[],
            AllLine:[],
            markerArray:[],
            lineArray:[],
            EventArray:[],
            HighMarker:{},
            infoWindow:{},
            SearchMarkerArray:[],
        }
    },
    methods:{
        addMarker(e){
            this.Map.remove(this.HighMarker)
            let content = "<div class='markerHighLight'></div>"
            this.HighMarker = new AMap.Marker({
                content: content,
                position:  [e.lng,e.lat],
            });
            this.Map.setCenter([e.lng,e.lat])
            this.Map.add(this.HighMarker)
        },
        CoverChange(e){
            if(e.indexOf(1) != -1){
                this.Map.add(this.markerArray)
            }else{
                this.Map.remove(this.markerArray)
            }

            if(e.indexOf(2) != -1){
                this.Map.add(this.lineArray)
            }else{
                this.Map.remove(this.lineArray)
            }
        },
        ClickOneAddress(e){
            this.Map.setCenter([e.location.lng,e.location.lat])
        },
        ClearMarker(){
            // this.Map.clearMap()
            this.Map.remove(this.SearchMarkerArray)
            this.SearchMarkerArray = []
        },
        SearchAddressFun(e){
            e.forEach((item,index)=>{
                let icon = new AMap.Icon({
                    size: new AMap.Size(58, 70),    // 图标尺寸
                    image: searchAddress,  // Icon的图像地址
                    imageSize: new AMap.Size(40, 60)   // 根据所设置的大小拉伸或压缩图片
                });

                let marker = new AMap.Marker({
                    position: [item.location.lng,item.location.lat],
                    offset: new AMap.Pixel(-10, -10),
                    icon: icon, // 添加 Icon 实例
                    title: '自定义图标',
                    zoom: 13
                });
                this.SearchMarkerArray.push(marker)
                this.Map.add(this.SearchMarkerArray)
                // let marker =  new AMap.Marker({
                //     map: this.Map,
                //     position:[item.location.lng,item.location.lat],
                //     extData:item,
                // });
            })
            this.Map.setCenter([e[0].location.R,e[0].location.Q])
        },
        ClickMenu(e){
            if(e == 2){
                this.Map.remove(this.HighMarker)
            }
            this.ruler.turnOff();
            if(this.MenuNum == e ){
                this.ruler.turnOff();
            }else{
                this.MenuNum = e
                if(e == 3){
                    this.ruler.turnOn();
                }
            }
        }
    },
    mounted() {
        this.Map = new AMap.Map("map", {
            center: [116.434381, 39.898515],
            zoom: 14
        });
        this.mouseTool = new AMap.MouseTool(this.Map)
        var startMarkerOptions= {
            icon: new AMap.Icon({
                size: new AMap.Size(19, 31),//图标大小
                imageSize:new AMap.Size(19, 31),
                image: "https://webapi.amap.com/theme/v1.3/markers/b/start.png"
            })
        };
        var endMarkerOptions = {
            icon: new AMap.Icon({
                size: new AMap.Size(19, 31),//图标大小
                imageSize:new AMap.Size(19, 31),
                image: "https://webapi.amap.com/theme/v1.3/markers/b/end.png"
            }),
            offset: new AMap.Pixel(-9, -31)
        };
        var midMarkerOptions = {
            icon: new AMap.Icon({
                size: new AMap.Size(19, 31),//图标大小
                imageSize:new AMap.Size(19, 31),
                image: "https://webapi.amap.com/theme/v1.3/markers/b/mid.png"
            }),
            offset: new AMap.Pixel(-9, -31)
        };
        var lineOptions = {
            strokeStyle: "solid",
            strokeColor: "#FF33FF",
            strokeOpacity: 1,
            strokeWeight: 2
        };
        var rulerOptions = {
            startMarkerOptions: startMarkerOptions,
            midMarkerOptions:midMarkerOptions,
            endMarkerOptions: endMarkerOptions,
            lineOptions: lineOptions
        };
        this.ruler = new AMap.RangingTool(this.Map, rulerOptions);
        equipmentListCov().then((e)=>{
            this.AllDevice = e.data
            this.AllDevice.forEach((item,index)=>{
                let marker =  new AMap.Marker({
                    size: new AMap.Size(25, 34),
                    map: this.Map,
                    position:[item.lng,item.lat],
                    extData:item,
                });
                marker.on('click',(e)=>{
                    let info = []
                    this.selectMarkerData = e.target.getExtData()
                    this.infoWindow = new AMap.InfoWindow({
                        content:["    <div  style=\"text-align: center;width:100%;\">",
                            e.target.getExtData().equipmentName,
                            "    </div>",
                            "    <div class='PopupAmap'>",
                            "      生产厂商：",e.target.getExtData().equipmentProducers,
                            "    </div>",
                            "    <div class='PopupAmap'>",
                            "      地址：",e.target.getExtData().equipmentHome,
                            "    </div>",
                            "    <div class='PopupAmap' style='text-align: center'>",
                            "    </div>"].join(""),
                        offset:new AMap.Pixel(0,-20)
                    });
                    this.infoWindow.open(this.Map,[e.target.getExtData().lng,e.target.getExtData().lat]);
                })
                this.markerArray.push(marker)
            })
        })
        LineListCov().then((e)=>{
            this.AllLine = e.data
            this.AllLine.forEach((item,index)=>{
                var polyline = new AMap.Polyline({
                    path:item.line,
                    strokeColor:'#2a5caa',
                    strokeOpacity: 1,
                    strokeWeight:2,
                    strokeStyle: "solid",
                    extData:item,
                })
                polyline.on('mouseover',(e)=>{
                    this.infoWindow = new AMap.InfoWindow({
                        content:["    <div  style=\"text-align: center;width:100%;\">",
                            e.target.getExtData().lineName,
                            "    </div>",
                            "    <div class='PopupAmap'>",
                            "      长度：",e.target.getExtData().length,
                            "    </div>",
                            "    <div class='PopupAmap' style='text-align: center'>",
                            "    </div>"].join(""),
                        offset:new AMap.Pixel(0,-20)
                    });
                    this.infoWindow.open(this.Map,[e.lnglat.R,e.lnglat.Q]);
                })
                polyline.setMap(this.Map)
                this.lineArray.push(polyline)
            })
        })
        PersonListCov().then((e)=>{
        })
    },
}